<template> 
  <div class="card" style="margin-top: 18px">
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                class="nav-link text-active-primary me-6"
                :to="'/barnyard/' + barnyardId + '/animals'"
                active-class="active"
              >
                {{ $t("menuPages.barnyardPanel.tabMenu.animals") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--
            <li class="nav-item" v-if="originalAnimalList.length != 0">
              <router-link
                class="nav-link text-active-primary me-6"
                :to="'/barnyard/' + barnyardId + '/vaccinations'"
                active-class="active"
              >
                {{ $t("menuPages.barnyardPanel.tabMenu.vaccination") }}
              </router-link>
            </li>
            -->
            <li class="nav-item" v-if="originalAnimalList.length != 0">
              <router-link
                class="nav-link text-active-primary me-6"
                :to="'/barnyard/' + barnyardId + '/feeds'"
                active-class="active"
              >
                {{ $t("menuPages.barnyardPanel.tabMenu.feed") }}
              </router-link>
            </li>
            <li class="nav-item" v-if="originalAnimalList.length != 0">
              <router-link
                class="nav-link text-active-primary me-6"
                :to="'/barnyard/' + barnyardId + '/weights'"
                active-class="active"
              >
                {{ $t("menuPages.barnyardPanel.tabMenu.weight") }}
              </router-link>
            </li>
            <!--
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-primary me-6"
                  to="/barnyardFeed"
                  active-class="active"
                >
                  {{ $t("menuPages.barnyardPanel.tabMenu.treatment") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-primary me-6"
                  to="/barnyardFeed"
                  active-class="active"
                >
                  {{ $t("menuPages.barnyardPanel.tabMenu.overheads") }}
                </router-link>
              </li>
            -->
          </ul>
        </div>
        <div class="card-header border-0" style="padding: 0">
          <div class="card-title">
            {{ $t("menuPages.vaccination.vaccination") }}
          </div>
        </div>
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
          <div class="table-responsive">
            <table
              class="
                table
                align-middle
                table-row-dashed
                fs-6
                gy-5
                dataTable
                no-footer
              "
              id="kt_customers_table"
              role="grid"
            >
              <thead>
                <tr
                  class="
                    text-start text-gray-400
                    fw-bolder
                    fs-7
                    text-uppercase
                    gs-0
                  "
                  role="row"
                >
                  <td>#</td>
                  <td>{{ $t("menuPages.vaccination.animal") }}</td>
                  <td>{{ $t("menuPages.vaccination.vaccine") }}</td>
                  <td>{{ $t("menuPages.vaccination.mililiter") }}</td>
                  <td>{{ $t("menuPages.vaccination.date") }}</td>
                  <td>{{ $t("menuPages.vaccination.status") }}</td>
                  <td>{{ $t("common.table.process") }}</td>
                </tr>
              </thead>

              <tbody class="fw-bold text-gray-600">
                <tr
                  class="odd"
                  v-for="(animal, index) in animalList"
                  :key="animal.animal.id"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                        <div class="d-flex align-items-center">
                          <div class="symbol symbol-45px me-5">
                            <img :src="animal.animal.profilePhotoUrl"  />
                          </div>
                          <div class="d-flex justify-content-start flex-column">
                            <a
                              href="#"
                              @click="routeToAnimalInfo(animal)"
                              class="
                                text-dark
                                fw-bolder
                                text-hover-primary
                                fs-6
                              "
                              >{{ animal.animal.name }}</a
                            >

                            <span
                              class="text-muted fw-bold text-muted d-block fs-7"
                            >
                              {{ animal.animal.earTag }}</span
                            >
                          </div>
                        </div>
                      </td>
                  <td>ŞAP Aşısı</td>
                  <td>10</td>
                  <td>11.11.2000</td>
                  <td>
                    <span class="badge fs-8 fw-bolder badge-light-success">
                      Yapıldı
                    </span>
                  </td>
                  <td>
                    <a
                      href="#"
                      class="
                        btn
                        btn-icon
                        btn-bg-light
                        btn-active-color-primary
                        btn-sm
                        me-1
                      "
                    >
                      <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
              <!--
              <thead>
                <tr
                  class="
                    text-start text-gray-400
                    fw-bolder
                    fs-7
                    text-uppercase
                    gs-0
                  "
                  role="row"
                >
                  <td>#</td>
                  <td>{{ $t("menuPages.vaccination.animal") }}</td>
                  <td>{{ $t("menuPages.vaccination.vaccine") }}</td>
                  <td>{{ $t("menuPages.vaccination.mililiter") }}</td>
                  <td>{{ $t("menuPages.vaccination.price") }}</td>
                  <td>{{ $t("menuPages.vaccination.date") }}</td>
                  <td>{{ $t("common.table.process") }}</td>
                </tr>
              </thead>

              <tbody class="fw-bold text-gray-600">
                <tr
                  class="odd"
                  v-for="(animal, index) in animalList"
                  :key="animal.animal.id"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ animal.animal.name }}</td>
                  <td>
                    <el-select v-model="formData.vaccine">
                      <el-option value="">Aşı seçiniz...</el-option>
                      <el-option value="D1">Deneme 1</el-option>
                      <el-option value="D2">Deneme 2</el-option>
                    </el-select>
                  </td>
                  <td>10</td>
                  <td>780 TL</td>
                  <td>11.11.2000</td>
                  <td>
                    <a
                      href="#"
                      class="
                        btn
                        btn-icon
                        btn-bg-light
                        btn-active-color-primary
                        btn-sm
                        me-1
                      "
                    >
                      <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                      </span>
                    </a>

                    <a
                      href="#"
                      class="
                        btn
                        btn-icon
                        btn-bg-light
                        btn-active-color-primary
                        btn-sm
                      "
                    >
                      <span class="svg-icon svg-icon-3">
                        <inline-svg
                          src="/media/icons/duotune/general/gen027.svg"
                        />
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
              -->
            </table>
          </div>
        </div>

        <!--begin::Pagination-->
        <div class="d-flex flex-stack flex-wrap pt-10">
          <!--begin::Controls-->
          <div class="d-flex flex-wrap my-1">
            <!--begin::Select wrapper-->
            <div class="m-0">
              <!--begin::Select-->
              <select
                name="status"
                data-control="select2"
                data-hide-search="true"
                class="
                  form-select form-select-white form-select-sm
                  fw-bolder
                  w-125px
                "
                v-model="pageSize"
                @change="fetchAnimals(1)"
              >
                <option value="5">5</option>
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
              <span>{{ $t("common.table.pagination.desc2") }}</span>
              <!-- Sayfa başı öğe sayısı -->

              <!--end::Select-->
            </div>
            <!--end::Select wrapper-->
          </div>
          <!--end::Controls-->

          <div class="fs-6 fw-bold text-gray-700">
            {{ totalItems }} {{ $t("common.table.pagination.desc") }}
            <!-- sonuç arasından -->
            {{ (currentPage - 1) * pageSize + 1 }} -
            {{ Math.min(currentPage * pageSize, totalItems) }}
            {{ $t("common.table.pagination.desc1") }}
            <!-- sonuç gösteriliyor. -->
          </div>

          <!--begin::Pages-->
          <ul class="pagination">
            <li
              class="page-item previous"
              @click="fetchAnimals(currentPage - 1)"
              :class="{ disabled: currentPage === 1 }"
            >
              <a href="#" class="page-link"><i class="previous"></i></a>
            </li>

            <li
              v-for="page in pageCount"
              :key="page"
              class="page-item"
              :class="{ active: currentPage === page }"
            >
              <a href="#" class="page-link" @click="fetchAnimals(page)">{{
                page
              }}</a>
            </li>

            <li
              class="page-item next"
              @click="fetchAnimals(currentPage + 1)"
              :class="{ disabled: currentPage === pageCount }"
            >
              <a href="#" class="page-link"><i class="next"></i></a>
            </li>
          </ul>
          <!--end::Pages-->
        </div>
        <!--end::Pagination-->
      </div>
    </div>
  </div>
  <CreateVaccine></CreateVaccine>
  <AddWeight></AddWeight>
</template>


<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  ROUTE_PAGES,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import { useRoute, useRouter } from "vue-router";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_PANELS,
  components: {
    CreateVaccine,
    AddWeight,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const animalList = ref<AnimalListModel[]>([]);
    const originalAnimalList = ref<AnimalListModel[]>([]);
    const swalNotification = new SwalNotification();
    const formData = ref({
      vaccine: "D1",
    });

    const barnyardId = route.params["id"];

    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newVaccine,
      () => {
        if (store.getters.newVaccine) fetchAnimals(1);
      }
    );

    const fetchAnimals = async (page) => {
      animalList.value = [];
      originalAnimalList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalListModel: AnimalListModel = {
        animal: {
          profilePhotoUrl: "media/animal/barnyard.jpg",
        },
        page: page,
        pageSize: pageSize.value,
        barnyardId: Number(barnyardId),
      };

      animalController
        .animalList(animalListModel)
        .then((response) => {
        if (response.isSuccess) {
            response.getValue().forEach((animal) => {
              totalItems.value = animal.pagination.total;
              currentPage.value = animal.pagination.page;
              pageCount.value = animal.pagination.pageCount;
              animalList.value.push(animal);
              originalAnimalList.value.push(animal);
            });
          } else {
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const search = ref<string>("");

    const searchItems = () => {
      if (search.value == "") {
        animalList.value = [...originalAnimalList.value];
      } else {
        animalList.value = originalAnimalList.value.filter(
          (animal) =>
            searchAnimalByName(animal, search.value) ||
            searchAnimalByEarTag(animal, search.value)
        );
      }
    };

    const searchAnimalByName = (animal, searchValue): boolean => {
      const animalName = animal.animal.name.toLowerCase();
      const searchLowerCase = searchValue.toLowerCase();

      return animalName.indexOf(searchLowerCase) !== -1;
    };

    const searchAnimalByEarTag = (animal, searchValue): boolean => {
      const animalTag = animal.animal.earTag.toLowerCase();
      const searchLowerCase = searchValue.toLowerCase();

      return animalTag.indexOf(searchLowerCase) !== -1;
    };

    const searchAnimalByGender = (animal, searchValue): boolean => {
      const animalGender = animal.animal.gender.toLowerCase();
      const searchLowerCase = searchValue.toLowerCase();

      return animalGender.indexOf(searchLowerCase) !== -1;
    };

    const routeToAnimalInfo = (animal) => {
      router.push({ name: ROUTE_PAGES.ANIMAL_INFO, params: { id: animal.animal.id } });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BARNYARD));
      fetchAnimals(1);
    });

    return {
      PAGE_TITLE_PATH,
      barnyardId,
      currentPage,
      pageCount,
      fetchAnimals,
      animalList,
      originalAnimalList,
      pageSize,
      totalItems,
      search,
      searchItems,
      formData,
      routeToAnimalInfo,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
